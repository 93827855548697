import React from 'react';

function JoinTeam() {
	return (
		<div>
			<div className='video-container-team'>
				<video autoPlay loop muted>
					<source src='/assets/img/videos/vid3.mp4' type='video/mp4' />
				</video>
				<div id='article1' className='wow zoomIn'>
					<a href='https://forms.gle/CmtU8rW94eUiBQUAA'>Join Our Team</a>
				</div>
			</div>

			<div className='container-fluid'>
				<div className='row'>
					<div className='col-lg-8'>
						<div className='jointop-nt'>
							<h1>Benefits of working with us</h1>
						</div>
						<div className='teamjoin_note1'>
							<p>
								A team that takes the time to know your interests and
								preferences and ready to answer your questions and needs
							</p>
						</div>
						<div className='teamjoinpage_img'>
							<img src='/assets/img/services_img/9.jpg' alt='' />
						</div>

						<div className=' teamjoin_note2'>
							<p style={{ marginTop: '20px' }}>Advantageous hourly rate</p>
							<p style={{ marginBottom: '30px' }}>
								Job opportunities in several sectors
							</p>
						</div>

						<div className='teamjoinpage_img'>
							<img src='/assets/img/services_img/11.jpg' alt='' />
						</div>
						<div className=' teamjoin_note2'>
							<p style={{ marginTop: '30px' }}>worklife balance</p>
							<p style={{ marginBottom: '30px' }}> Flexible working schedule</p>
						</div>

						<div className='teamjoinpage_img'>
							<img src='/assets/img/services_img/10.jpg' alt='' />
						</div>

						<div className='teamjoin_note3'>
							<p style={{ marginTop: '30px' }}>CPR training</p>
							<p style={{ marginBottom: '100px' }}>
								Training on-demand, including orientation, to allow to sharpen
								your efficiency
							</p>
						</div>
						{/* 	<div className='row'>
							<div className='col-sm-6'>
								<div className='teamjoinpage_img'>
									<img src='/assets/img/services_img/9.jpg' alt='' />
								</div>
							</div>
							<div className='col-sm-6 teamjoin_note '>
								<p>
									A team that takes the time to know your interests and
									preferences and ready to answer your questions and needs
								</p>
								<p>Flexible working schedule</p>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-6'>
								<div className='teamjoinpage_img'>
									<img src='/assets/img/services_img/10.jpg' alt='' />
								</div>
							</div>
							<div className='col-sm-6 teamjoin_note'>
								<p>Advantageous hourly rate.</p>
								<p>worklife balance</p>
								<p>Job opportunities in several sectors.</p>
							</div>
						</div>

						<div className='row'>
							<div className='col-sm-6'>
								<div className='teamjoinpage_img'>
									<img src='/assets/img/services_img/11.jpg' alt='' />
								</div>
							</div>
							<div className='col-sm-6 teamjoin_note'>
								<p>
									Training on-demand, including orientation, to allow to sharpen
									your efficiency.
								</p>
								<p>We offer CPR training.</p>
							</div>
						</div>*/}
					</div>
					<div className='col-lg-4 '>
						<img
							className='cheerimg'
							src='/assets/img/services_img/15.png'
							alt=''
						/>
						<div className='joinbottom-nt'>
							<p>Refer someone</p>
							<p> and get paid</p>
							<a
								className='big-link-1'
								href='https://forms.gle/c6amGtddmGvnbVSv9'>
								Clicke Here!
							</a>
						</div>
					</div>
				</div>
			</div>

			<div
				className='bg-image  page-hero '
				style={{
					backgroundImage: 'url(/assets/img/services_img/7.jpg)',
				}}>
				<a
					className='big-link-1 linkpos'
					href='https://forms.gle/CmtU8rW94eUiBQUAA'>
					Clicke Here!
				</a>
			</div>
		</div>
	);
}

export default JoinTeam;
