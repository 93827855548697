import React from "react";

function Services() {
  return (
    <div className="serpage_wapper">
      <div className="page-title-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 page-title wow zoomIn">
              <span aria-hidden="true" className="">
                <i
                  style={{ fontSize: "38px", color: "rgb(4, 10, 97)" }}
                  className="fa fa-user-md"
                ></i>
              </span>

              <h1>Services </h1>
            </div>
          </div>
          <div className="about-us-hz"></div>
        </div>
      </div>

	  
    <div className="container-fluid">
        <div className="row flex-column-reverse  ">
          <div className="col-md-8 mobile-Ser">
            <div
              className="page-banner bg-image"
              style={{
                backgroundImage: "url(/assets/img/services_img/17.jpg)",
              }}
            />

            <p>
              We provide permanent, temporary or per diem recruitment solutions
              that span across all levels of seniority throughout the healthcare
              sector
            </p>

            <p>
              Our main client base includes Nursing Home, Residential Care Home
              Groups, Private Hospitals and Mental Health Organizations
            </p>
          </div>

          <div className="col-md-4 service-text ">
            <h1>HEALTHCARE STAFFING</h1>

            <p>
              We provide permanent, temporary or per diem recruitment solutions
              that span across all levels of seniority throughout the healthcare
              sector
            </p>

            <p>
              Our main client base includes Nursing Home, Residential Care Home
              Groups, Private Hospitals and Mental Health Organizations
            </p>
          </div>
        </div>
    

      <div className="row ">
        <div className="col-md-4 service-text">
          <h1>TRAINING CENTER</h1>

          <p>We provide professional health training.</p>

          <div className="service-icon">
            <img src="/assets/img/icons/8.png" alt="Logo" />
          </div>
        </div>
        <div className="col-md-8 mobile-Ser ">
          <div
            className="page-banner bg-image bounceInUp"
            style={{
              backgroundImage: "url(/assets/img/services_img/16.jpg)",
            }}
          />

          <p>We provide professional health training.</p>

          <div className="service-icon">
            <img src="/assets/img/icons/8.png" alt="Logo" />
          </div>
        </div>
      </div>

      <div className="row flex-column-reverse">
        <div className="col-md-8  mobile-Ser">
          <div
            className="page-banner bg-image"
            style={{
              backgroundImage: "url(/assets/img/services_img/18.jpg)",
            }}
          />
          <p>
            We are American Heart Association certified provider. We provide CPR
            and First Aid Training programs to healthcare professionals,
            parents, grandparents, teachers, child care providers, and anyone
            interested in learning lifesaving CPR and First Aid
          </p>
        </div>

        <div className="col-md-4 service-text ">
          <h1>CPR</h1>

          <p>
            We are American Heart Association certified provider. We provide CPR
            and First Aid Training programs to healthcare professionals,
            parents, grandparents, teachers, child care providers, and anyone
            interested in learning lifesaving CPR and First Aid
          </p>
        </div>
      </div> 

</div>
    </div>
  );
}

export default Services;
