import React from 'react';

function Healthcarestaffing() {
	return (
		<div>
			<div
				className='bg-image  page-hero '
				style={{
					backgroundImage: 'url(/assets/img/img2.jpg)',
				}}>
				<div className='hero-section'>
					<div className='container text-left wow zoomIn display-4'>
					<h1>Facility care</h1>
					<h1>services</h1>
					<h1>offered by</h1>
					<h1>competent</h1>
					<h1>caregivers</h1>
						{/* <h1>Recruiting And </h1>
						<h1>Workforce</h1>
						<h1>Solution</h1> */}
					</div>
				</div>
			</div>
			<div>
				<div className='row row_wapper'>
					<div className='col-md-6  wow zoomIn  health_item1 border-bottom borderb-10  border-10  '>
						<a href='/RequesteSerLink'>REQUEST OUR SERVICE</a>
					</div>

					<div className='col-md-6 health_item2 wow zoomIn health_item border-start border-top border-10'>
						<a href='/JoinTeam'>JOIN OUR TEAM</a>
					</div>
				</div>
			</div>
			<div className='container text-justify mobile-note'>
				<h1>
					We know how difficult it could be to get the right support to reach
					your goals. The hiring process can be challenging, expensive, and
					time-consuming. Let us help you find the right fit for your needs.
				</h1>
			</div>
			<div className='container'>
				<div className='row no-gutters'>
					<div className='col-lg-8 wow fadeInDown'>
						<div className='video-control'>
							<video src='/assets/img/videos/vid1.mp4' autoPlay loop muted />
						</div>
					</div>
					<div className='col-lg-4 left-text wow fadeInUp'>
						<p>
							FORTUNATE HEALTHCARE STAFFING offers unparalleled staff
							recruitment and placement service in the health sector throughout
							New Jersey
						</p>
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='text-wapper'>
					<h1>
						<img src='/assets/img/icons/5.png' alt='Logo' />
						Who we are
					</h1>
					<p>
						We are led by a group of NURSES who are professionally dedicated to
						helping improve the lives of others. Yet, we know the workload of
						staff shortage.
					</p>
					<p>
						Our team of experienced recruiters and staffing consultants with
						diverse clinical specializations are well-connected within the
						industries a result, we serve and can draw from a large pool of
						highly skilled and experienced candidates.
					</p>
				</div>
			</div>
			<div className='container'>
				<div className='text-wapper'>
					<h1>
						<img src='/assets/img/icons/5.png' alt='Logo' />
						Our mission
					</h1>
					<p>
						Transmit the best services to various public and private
						institutions. Offer healthcare professionals the opportunity to work
						in a flexible environment. Ensuring high-quality patient care.
					</p>
				</div>
			</div>

			<div className='container'>
				<div className='text-wapper'>
					<h1>
						<img src='/assets/img/icons/5.png' alt='Logo' />
						Our team
					</h1>
					<li> RN</li>
					<li>LPN / LVN</li>
					<li> CNA</li>
					<li>HHA</li>
					<li>Allied health </li>
					<li style={{ marginBottom: '15px' }}> Labs Technician</li>
				</div>
			</div>

			<div className='container'>
				<div className='text-wapper'>
					<h1>
						<img src='/assets/img/icons/5.png' alt='Logo' />
						Type of contracts
					</h1>
					<li> Per diem</li>
					<li>Block Staffing for multiple shifts </li>
					<li> Outsourcing solution</li>
					<li>Temporary-to-hire</li>
					<li style={{ marginBottom: '15px' }}>Direct to hire </li>
				</div>
			</div>
			<div className='botton_note'>
				<h1>In health-related services, we are your reference</h1>
			</div>
		</div>
	);
}

export default Healthcarestaffing;
