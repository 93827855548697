import React from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  const form = React.useRef();
  const [sent, setSent] = React.useState("");
  const [status, setStatus] = React.useState("Submit");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_elbc49i",
        "template_e4jedma",
        form.current,
        "user_044jT8q1ApcL1azmiSQJ6"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("sending...");
          const { name, email, subject, message } = e.target.elements;
          setTimeout(() => {
            name.value = "";
            email.value = "";
            subject.value = "";
            message.value = "";
            setSent(
              <p style={{ color: "green", fontSize: "25px" }}>
                Your message was successfully sent!
              </p>
            );
            setStatus("Submit");
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          setSent(
            <p style={{ color: "red", fontSize: "25px" }}>
              Soemthing went wrong we were able to sent your message.
            </p>
          );
        }
      );
  };

  return (
    <div>
      <div className="page-title-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 page-title wow zoomIn">
              <span
                aria-hidden="true"
                style={{ color: "rgb(4, 10, 97)" }}
                className="icon_mail"
              ></span>
              <h1>Contact Us </h1>
            </div>
          </div>
          <div className="about-us-hz"></div>
        </div>
      </div>

      <div className="contact-us-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-7 contact-form wow fadeInLeft">
              {sent}
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="hidden"
                  name="autor-name"
                  value="Farah Desjardin"
                />
                <div className="form-group">
                  <label htmlFor="contact-name">Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name..."
                    className="contact-name form-control"
                    required
                    id="contact-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contact-email">Email</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your email..."
                    className="contact-email form-control"
                    id="contact-email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contact-subject">Subject</label>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Your subject..."
                    className="contact-subject form-control"
                    id="contact-subject"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contact-message">Message</label>
                  <textarea
                    name="message"
                    placeholder="Your message..."
                    className="contact-message form-control"
                    required
                    id="contact-message"
                  ></textarea>
                </div>
                <button type="submit" className="btn">
                  {status}
                </button>
              </form>
            </div>
            <div className="col-sm-5 contact-address wow fadeInUp">
              <h3>We Are Here</h3>
              <div className="map"></div>
              <h3>Address</h3>
              <p>
                215 E Laurel Road Stratford,
                <br /> New Jersey 08084
              </p>
              <p>Phone: (609) 474-6674</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
