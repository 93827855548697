import React from 'react';

function Footer() {
	return (
		<footer>
			<div className='container'>
				<div className='row'>
					<div className='col-sm-3 footer-box wow fadeInUp'>
						<h4>About Us</h4>
						<div className='footer-box-text'>
							<p>
								<a href='/RequesteSerLink'>REQUEST OUR SERVICE</a>
							</p>
							<p>
								<a href='/JoinTeam'>JOIN OUR TEAM</a>
							</p>

							<p>
								<a href='/about'>Read more...</a>
							</p>
						</div>
					</div>
					<div className='col-sm-3 footer-box wow fadeInDown'>
						<h4>Email Updates</h4>
						<div className='footer-box-text footer-box-text-subscribe'>
							<p>
								Enter your email and you'll be one of the first to get new
								updates:
							</p>
							<form  method='post'>
								<div className='form-group'>
									<label className='sr-only' htmlFor='subscribe-email'>
										Email address
									</label>
									<input
										type='text'
										name='email'
										placeholder='Email...'
										className='subscribe-email form-control'
										id='subscribe-email'
									/>
								</div>
								<button type='submit' className='btn'>
									Subscribe
								</button>
							</form>
							<p className='success-message'></p>
							<p className='error-message'></p>
						</div>
					</div>
					<div className='col-sm-3 footer-box wow fadeInUp'>
						<h4>Flickr Photos</h4>
						<div className='footer-box-text flickr-feed'></div>
					</div>
					<div className='col-sm-3 footer-box wow fadeInDown'>
						<h4>Contact Us</h4>
						<div className='footer-box-text footer-box-text-contact'>
							<p>
								<span aria-hidden='true' className='icon_pin'></span> Address: 215 E
								Laurel Road Stratford, New Jersey 08084
							</p>
							<p>
								<span aria-hidden='true' className='icon_phone'></span> Phone: +1
								(609) 474-6674
							</p>
							<p>
								<span aria-hidden='true' className='icon_profile'></span> Skype:
								fortunate_Business
							</p>
							<p>
								<span aria-hidden='true' className='icon_mail'></span> Email:
								<a
									href='mailto: fortunate@fortunatehhcs.com'
									className='atag'
									style={{ margin: '0px 5px 0px ' }}>
									contact@fortunatehhcs.com
								</a>
							</p>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-sm-12 wow fadeIn'>
						<div className='footer-border'></div>
					</div>
				</div>
				<div className='row'>
					<div className='col-sm-7 footer-copyright wow fadeIn'>
						<p>&copy; Fortunate HealthCare - All rights reserved.</p>
					</div>
					<div className='col-sm-5 footer-social wow fadeIn'>
						<a href='https://m.facebook.com/100075415696688/'>
							<span className='social_facebook'></span>
						</a>
						<a href='https://instagram.com/fortunatehhcs?utm_medium=copy_link'>
							<span className='social_instagram'></span>
						</a>
						<a href='/'>
							<span className='social_twitter'></span>
						</a>
						<a href='https://youtube.com/channel/UCgRzfX7_syk0wZUoZ6ffdfg'>
							<span className='social_youtube'></span>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
