import React from "react";
import emailjs from "emailjs-com";

function Home() {
  const form = React.useRef();
  const [sent, setSent] = React.useState("");
  const [status, setStatus] = React.useState("Submit");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_elbc49i",
        "template_e4jedma",
        form.current,
        "user_044jT8q1ApcL1azmiSQJ6"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("sending...");
          const { name, email, phone, message } = e.target.elements;
          setTimeout(() => {
            name.value = "";
            email.value = "";
            phone.value = "";
            message.value = "";
            setSent(<p style={{ color: "green" }}>success!</p>);
            setStatus("Submit");
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          setSent(<p style={{ color: "red" }}>FAIL!</p>);
        }
      );
  };
  return (
    <div>
      <section id="main-slider">
        <div class="owl-carousel">
          {/* Image1 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/25.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6 ">
                    <div class="carousel-content img-carousel-note1">
                      <h1>Ambulation</h1>
                      <h1>Transportation</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image2 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/29.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="carousel-content img-carousel-note">
                      <h1>Errands</h1>
                      <h1>Grocery</h1>
                      <h1>Light Housekeeping</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image3 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/31.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="carousel-content img-carousel-note">
                      <h1>Personal Care</h1>

                      <h1>RESPITE care</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image4 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/35.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="carousel-content img-carousel-note"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image4 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/37.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6"></div>
                  <div class="col-sm-6">
                    <div class="carousel-content img-carousel-note">
                      <h1> POSTNATALE CARE</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image5 */}
          <div
            class="item"
            style={{
              width: "100%",
              height: "700px",
              backgroundImage: "url(/assets/img/homecare-2/27.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <div class="slider-inner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="carousel-content img-carousel-note">
                      <h1>Nursing Care</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="homeCareTitle">
        <h1>HOMECARE</h1>
      </div>
      <div className=" Hz-ColoredLine">
        <div class="col-sm-6 text-left btn-need-training ">
          <a href="https://www.fortunatetrainingcenter.com" target="_blank">
            Need training?
          </a>
        </div>
        <div class="col-sm-6 a-items text-right">
          <a
            class="btn btn-primary btn-lg"
            href="https://www.fortunatetrainingcenter.com"
            target="_blank"
          >
            HHA TRAINING
          </a>
          <a class="btn btn-primary btn-lg" href="/CPRClass">
            CPR CLASSES
          </a>

          <a class="btn btn-primary btn-lg" href="/Healthcarestaffing">
            STAFFING
          </a>
        </div>
      </div>
      <section className="animated-number">
        <div class=" pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-9 ">
                <div className="row">
                  <div className="col-sm-6">
                    <div class="img-place custom-img-1">
                      <img src="/assets/img/homecare-2/12.png" alt="" />
                    </div>
                    <div className="miniImgNotes">
                      <h4>A dedicated care team</h4>
                      <p>
                        Our services support your daily activities and help
                        maintain your autonomy
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="miniImgNotes1">
                      <h4>24/7 Availability</h4>
                      <p>
                        Clients and Families can reach care managers even at
                        night and on weekends
                      </p>
                    </div>
                    <div class="img-place custom-img-1">
                      <img src="/assets/img/homecare-2/11.png" alt="" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div class="img-place custom-img-1">
                      <img src="/assets/img/homecare-2/1.png" alt="" />
                    </div>
                    <div className="miniImgNotes">
                      <h4>Like in the hospital but at home</h4>
                      <p>we promote a safe return home after hospitalization</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="miniImgNotes">
                      <h4>Balanced care</h4>
                      <p>
                        Our unique approach promotes a healthy body and mind
                      </p>
                    </div>
                    <div class="img-place custom-img-1">
                      <img src="/assets/img/homecare-2/10.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 reqWrapper">
                <h3>Do you have questions?</h3>
                <p>Contact us for a Free In-Home Assessment 609-474-6674</p>
                <div className="reqWrapper2">
                  {sent}
                  <form ref={form} onSubmit={sendEmail}>
                    <input
                      type="text"
                      id="fname"
                      name="name"
                      placeholder="Your name.."
                    />

                    <input
                      type="text"
                      className="contact-email form-control"
                      id="lname"
                      name="email"
                      placeholder="Email.."
                    />
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Phone #.."
                      required
                    />

                    <label for="country">How did you hear about us?</label>
                    <br />
                    <select id="country" name="country">
                      <option value="australia">GOOGLE</option>
                      <option value="canada">FACEBOOK</option>
                      <option value="usa">YELP</option>
                      <option value="usa">FRIEND OR FAMILY</option>
                      <option value="usa">WHATSAPP</option>
                      <option value="usa">FAIR</option>
                    </select>
                    <br />

                    <textarea
                      id="subject"
                      name="message"
                      placeholder="Write something.."
                    ></textarea>

                    <button type="submit" className="btn">
                      {status}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="servInfo">
                <h3>Our services</h3>
                <p>
                  Fortunate HomeCare offers flexible care schedules which gives
                  clients the freedom to decide when they want the caregiver
                  present and how much care is needed. Our clients have the
                  opportunity to choose daytime, night, or weekend care
                  schedules. Clients even can choose to set care schedules
                  around medical appointments or outings.
                </p>
                <p>
                  Whether it be hourly services, Live-In care, at home or in a
                  hospital or residence, our services are tailored to your
                  needs. All of our caregivers are carefully screened and
                  trained thereby providing the highest quality care.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="listSerinfo">
                <ul>
                  <li>nursing Care</li>
                  <div className="noteNrs">
                    <p>
                      nursing care In consultation with the doctor, our
                      registered nurse will set up a plan of care depending on
                      the person's needs. Nursing care may include wound
                      dressing, ostomy care, intravenous therapy, administering
                      medication, monitoring the general health of the patient,
                      pain control, and other health support.
                    </p>
                  </div>
                  <li>Personal care</li>
                  <div className="noteNrs">
                    <p>
                      Personal care Personal Care supports basic personal
                      hygiene and activities of daily living. This can include
                      basic tasks, such as bathing, mobility assistance,
                      incontinence, and toileting care, preparing and serving
                      meals, to more hands-on personal care including position
                      changes for those clients that are bedbound and
                      specialized care for those with memory issues or cognitive
                      impairments, fall risk assessment and prevention, change
                      in condition monitoring. Personal home care services can
                      also include assistance with outings, social events,
                      shopping trips, and other activities of interest to
                      maintain engagement both physically and emotionally.
                      Personal care activities include:
                    </p>
                  </div>
                  <li>New Mom care</li>
                  <li>Post surgery care</li>
                  <li>Alzheimer / Dementia Care</li>
                  <li>hospice care</li>
                  <li>RESPITE care</li>
                  <li>in facility Care</li>
                  <li>companionship</li>
                  <li>medication reminders</li>
                  <li>meals preparation</li>
                  <li>errands / grocery</li>
                  <li>ambulation / transportation</li>
                  <li>light housekeeping</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="video-container-home">
        <video autoPlay loop muted>
          <source src="/assets/img/videos/vid2.mp4" type="video/mp4" />
        </video>
        <div className="left-note  wow zoomIn">
          <h1>Recruiting And </h1>
          <h1>Workforce</h1>
          <h1>Solution</h1>
        </div>
      </div> */}
      {/* <div className="services-container" >
        <div className="container " >
          <div className="row flex-md-row" >
            <div className="col-md-4">
              <div className="service wow fadeInUp">
                <div className="service-icon">
                  <img src="/assets/img/icons/2.png" alt="Logo" />
                </div>
                <h3> STAFFING </h3>

                <h1
                  style={{
                    marginTop: "-10px",
                    color: "black",
                    visibility: "hidden",
                  }}
                >
                  HEALTH
                </h1>

                <a className="big-link-1" href="/Healthcarestaffing">
                  Read more
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service wow fadeInDown">
                <div className="service-icon">
                  <img src="/assets/img/icons/6.png" alt="Logo" />
                </div>
                <h3>TRAINING CENTER </h3>
                <h1
                  style={{
                    marginTop: "-10px",
                    color: "black",
                    visibility: "hidden",
                  }}
                >
                  HEALTH
                </h1>

                <a className="big-link-1" href="/Trainingpage">
                  Read more
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service wow fadeInDown">
                <div className="service-icon">
                  <img src="/assets/img/icons/1.png" alt="Logo" />
                </div>
                <h3>CPR </h3>
                <h1
                  style={{
                    marginTop: "-10px",
                    color: "black",
                    visibility: "hidden",
                  }}
                >
                  HEALTH
                </h1>

                <a className="big-link-1" href="/CPRClass">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div style={{marginTop:'1000px'}}>
        hello world!
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 wow fadeInUp">
            <div className="officehours">
              <h1>Hours of Operation</h1>
              <div className="officehoursItem">
                <h1 style={{ fontWeight: "bold" }}> Monday-Friday</h1>
                <h1> 9:00 am – 5:00 pm </h1>
                <h1 style={{ fontWeight: "bold" }}>Saturday</h1>
                <h1> 9:00 am - 12:00 pm </h1>
                <h1 style={{ fontWeight: "bold" }}>Sunday</h1>
                <h1>Closed</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-8 wow fadeInDown testimonials-title ">
            <h1>Testimonials</h1>
            <div className="reviews-container">
              <div id="google-reviews"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="botton_note">
        <h1>In health-related services, we are your reference</h1>
      </div>
    </div>
  );
}

export default Home;
// homecare@fortunatehhcs.com
