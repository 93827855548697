import React from 'react';

function Resources() {
	return (
		<div className='container'>
			<div className='page-title-container'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-10 col-sm-offset-1 page-title wow zoomIn'>
							<span
								aria-hidden='true'
								style={{ color: 'rgb(4, 10, 97)' }}
								className='icon_comment'></span>
							<h1>Resources </h1>
						</div>
					</div>
				</div>
			</div>

			<div className='about-us-hz'></div>

			<div className='resouces-text'>
				<p>
					Thank you for visiting us! Here are a few of the sites around the web
					that you may find helpful. Each link is configured to open in a new
					browser window. We are not responsible for the content of external
					pages.
				</p>

				<div className='resources-item'>
					.<h1>Links to Helpful Online Resources</h1>
					<h3>AARP</h3>
					<p>
						Benefits, Advocacy, and Information on Aging.
						<br />
						<a href='https://www.aarp.org/'>https://www.aarp.org/</a>
					</p>
					<h3>The Joint Commission </h3>
					<p>
						The Joint Commission enterprise is a global driver of quality
						improvement and patient safety in healthcare.
						<br />
						<a href='https://www.jointcommission.org/'>
							https://www.jointcommission.org/
						</a>
					</p>
					<h3>Alzheimer's Foundation of America</h3>
					<p>
						Non-profit foundation comprised of member and associate member
						organizations across the U.S. dedicated to meeting the educational,
						social, and emotional needs of individuals with Alzheimer's disease
						and related illnesses, and their families and caregivers. <br />
						<a href='https://www.alzfdn.org/'>https://www.alzfdn.org/</a>
					</p>
					<h3>Elder abuse</h3>
					<p>
						Helpful resources to educate the public and assist victims of elder
						abuse, including links to local hotline and prevention programs{' '}
						<br />
						<a href='https://www.ncoa.org/'>https://www.ncoa.org/</a>
					</p>
					<h3>Fall prevention</h3>
					<p>
						Free booklet on “Safety For Older Consumers - Home Safety
						Checklist”. That covers all the essentials needed to prevent falls
						and accidents in and around the home.
						<br />
						<a href='https://www.cpsc.gov'>https://www.cpsc.gov</a>
					</p>
				</div>
			</div>
			<br />
			<br />
		</div>
	);
}

export default Resources;
