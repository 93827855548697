import React from 'react';

function About() {
	return (
		<div className='container'>
			<div className='page-title-container'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-10 col-sm-offset-1 page-title wow zoomIn'>
							<span
								aria-hidden='true'
								style={{ color: 'rgb(4, 10, 97)' }}
								className='icon_profile'></span>
							<h1>About Us </h1>
						</div>
					</div>
				</div>
			</div>

			<div className='about-us-hz'></div>
			<br />

			<div className='container'>
				<div className='row '>
					<div className='col-lg-4 '>
						<div className='about-us-purp wow fadeInUp'>
							<br />
							<h1>OUR PURPOSE</h1>
							<p>
								Fortunate HealthCare is locally owned and operated in South
								Jersey. Our detailed and rigorous Qualified Caregiver Screening
								Process ensures we select only the warmest Experienced,
								Compassionate, Accountable, Reliable Enthusiastic to be part of
								our team. We take pride in delivering a level of care, customer
								attention, communication, and transparency unmatched in the
								healthcare industry.
							</p>
						</div>
					</div>
					<div className='col-lg-8 '>
						<div className='imgcontainer wow fadeInDown'>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/6.jpg'
								alt='First slide'
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='about-us-hz'></div>

			<div className='container'>
				<div className='row no-gutters flex-column-reverse flex-lg-row '>
					<div className='col-lg-8 '>
						<br />
						<div className='imgcontainer wow fadeInUp'>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/5.jpg'
								alt='First slide'
							/>
						</div>
					</div>
					<div className='col-lg-4 about-us-miss'>
						<h1>MISSION</h1>
						<p>
							Fortunate HealthCare recognizes the value of every person and
							facility we serve. We honor the importance of partnering with our
							community and other healthcare providers in inclusive care
							coordination. Through the creation of supportive team environments
							for patients, employees, and clinical staff, we are dedicated to
							providing holistic excellence in skilled care.
						</p>
					</div>
				</div>
			</div>
			<div className='about-us-hz'></div>
			<br />

			<div className='container'>
				<div className='row no-gutters '>
					<div className='col-lg-4 '>
						<div className='about-us-purp wow fadeInUp'>
							<br />
							<h1>CULTURE & VALUE</h1>
							<p>
								Fortunate HealthCare was created on a core set of values that
								drives our culture. Our commitment to the following values is
								the foundation of our ability to provide the highest quality of
								care. Passion & Enthusiasm for Providing the Highest Quality
								Care Customer Service Accountability, Reliability & Loyalty
								Respect, Appreciation, Patience & Compassion Commitment to
								Continuous Improvement Teamwork & Professionalism
							</p>
						</div>
					</div>
					<div className='col-lg-8 '>
						<div className='imgcontainer wow fadeInDown'>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/4.jpg'
								alt='First slide'
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='about-us-hz'></div>
			<div className='container'>
				<div className='row '>
					<div className='col-md-4 mobile-img'>
						<div className=' wow fadeInUp'>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/2.jpg'
								alt='First slide'
							/>
						</div>
					</div>
					<div className='col-md-4 mobile-img'>
						<div className=' wow fadeInDown '>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/3.jpg'
								alt='First slide'
							/>
						</div>
					</div>
					<div className='col-md-4 '>
						<div className=' wow fadeInUp'>
							<img
								className=' d-block w-100'
								src='/assets/img/slider/1.jpg'
								alt='First slide'
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='about-us-note'>
					<p>
						While there are other providers of nursing and staffing services,
						here are just some reasons why FORTUNATE HEALTHCARE stands above the
						rest:
					</p>

					<li>
						<span>Commitment: </span>
						We are highly committed to provide our clients with comprehensive
						healthcare service solutions. We dedicate our time to ensuring that
						we help you find the right people, with the right qualifications,
						for the right job.
					</li>
					<li>
						<span>Comprehensive services: </span>
						Whether you need: home nursing care staffing, nursing staffing, or
						emergency nurse staffing services, our comprehensive services can
						meet your needs!
					</li>
					<li>
						<span>Reasonable prices: </span>
						With our Services, finding highly-trained staff doesn’t have to be
						expensive! We offer you very affordable prices that will fit your
						budget.
					</li>
				</div>
			</div>
		</div>
	);
}

export default About;
