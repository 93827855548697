import React from 'react';

function CPRClas() {
	return (
		<div>
			<div className='video-container'>
				<video autoPlay loop muted>
					<source src='/assets/img/videos/cprVid.mp4' type='video/mp4' />
				</video>
				<div id='article' className='wow zoomIn'>
					<h1>CPR + AED</h1>

					<p>
						You can receive your 2 years American Heart Association CPR Card
						<span style={{ marginLeft: '5px' }}>/</span>
						<span style={{ marginLeft: '5px' }}>Certificate today!</span>
					</p>
					<a href='https://forms.gle/UUTrK1a7pBgaP6hW9'>Book with Us</a>
				</div>
			</div>

			<div className='container '>
				<div className='text-control'>
					<p>
						Being certified in CPR, Infant CPR, AED, or First Aid can mean
						saving a person’s life. Getting your certification can give
						laypersons the skills and knowledge to save lives in an emergency
						situation.
					</p>
				</div>
				<div className='container'>
					<div className=' clasNote '>
						<p>Class offered</p>
					</div>
					<div className='row no-gutters'>
						<div className='col-md-6 trainingsec'>
							<h3>BLS CERTIFICATION TRAINING</h3>
							<p>
								​BLS for Healthcare Providers course is intended for those in
								the medical field or are studying to go into the medical field.
							</p>
							<p>
								Doctors, Nurses, Medical Assistants, Dentists, Paramedics, EMTs,
								Firefighters and anyone that works in a hospital or medical
								clinic.
							</p>
						</div>
						<div className='col-md-6 trainingsec'>
							<h3>Heartsaver First Aid Training</h3>
							<p>
								This course is designed for lay person. The class is perfect for
								Foster Parents, Teachers, Coaches, Those Working with Seniors /
								Developmental Challenged Individuals, Babysitters, Fitness
								Instructors, Church Workers and Anyone the Desired Knowledge
								about Basic First Aid and CPR. OSHA Approved. ​
							</p>
						</div>
					</div>
				</div>
				<div>
					<h1 className='train_note'>Training Flexibility</h1>
					<div
						className='page-banner bg-image'
						style={{
							backgroundImage: 'url(/assets/img/slider/7.png)',
						}}
					/>
				</div>
				<div className='cpr-extra-note'>
					<p>
						Wondering where to get CPR certification? How about at your own
						office or a venue of preference?
					</p>
					<p>
						Fortunate CPR + AED classes can be delivered onsite, whether you run
						an educational institution or a corporate enterprise. We work with
						employees, students and staff members at a location of preference to
						ensure convenience and versatility. Also, we will carry out the
						classes at a time deemed convenient for everyone involved.
					</p>
					<p>
						The minimum number of people needed for onsite training is eight.
						Small groups are maintained to ensure everyone’s participation and a
						chance to acquire some hands-on experience.
					</p>
					<p>
						Not only will you benefit from onsite training, we can also provide
						a group discount whenever your organization has a bigger number of
						people to go through the process. All you need to do is
						<a style={{ margin: '0px 0px 0px 5px' }} href='tel:609-474-6674'>
							contact us
						</a>{' '}
						to receive your special pricing offer.
					</p>
				</div>
			</div>
			<div className='botton_note'>
				<h1>In health-related services, we are your reference</h1>
			</div>
		</div>
	);
}
export default CPRClas;
