import React from 'react';

function Services() {
	return (
		<div className='serpage_wapper'>
			<div
				className='bg-image  page-hero '
				style={{
					backgroundImage: 'url(/assets/img/services_img/8.jpg)',
				}}>
				<div className='row'>
					<div className='container-fluid'>
						<div className='col-sm-3'>
							<img
								className='serpage_img1'
								src='/assets/img/services_img/1.jpg'
								alt=''
							/>
						</div>
						<div className='col-sm-3'>
							<img
								className='serpage_img2'
								src='/assets/img/services_img/4.jpg'
								alt=''
							/>
						</div>
						<div className='col-sm-3'>
							<img
								className='serpage_img1'
								src='/assets/img/services_img/2.jpg'
								alt=''
							/>
						</div>
						<div className='col-sm-3'>
							<img
								className='serpage_img2'
								src='/assets/img/services_img/6.jpg'
								alt=''
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='row row_wapper'>
				<div className='col-md-6 wow zoomIn  serpage_note1 '>
					<p>
						We recruit the best healthcare professionals of any specialty who
						will provide a high level of continual patient care for quality
						outcomes
					</p>
				</div>

				<div className='col-md-6 wow zoomIn serpage_note2 '>
					<p>
						Your facility will be covered through unpredicted vacancies,
						personnel shortages, and staffing challenges.
					</p>
				</div>
			</div>

			<div className='serv_text'>
				<p>Looking for staffing solutions to meet</p>

				<p> your unique staffing requirements? </p>
			</div>
			<div className='servpage_sqrt'>
				<div className='row container flex-column-reverse '>
					<div className='col-lg-8'>
						<div className='squar_link'>
							<a href='https://forms.gle/HtCeWrCKNYpFJjGq6'>Click here</a>
						</div>
					</div>

					<div className='col-lg-4 wow fadeInRight img-Req" '>
						<img src='/assets/img/services_img/12.png' alt='' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Services;
