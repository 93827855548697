import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import NavbarPilot from "./Components/NavbarPilot";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import About from "./Components/About";

import CPRClass from "./Components/CPRClass";
import Services from "./Components/Services";
import Resources from "./Components/Resources";
import Healthcarestaffing from "./Components/Healthcarestaffing";
import RequesteSerLink from "./Components/RequesteSerLink";
import JoinTeam from "./Components/JoinTeam";
import Trainingpage from "./Components/Trainingpage";
import React from "react";

function App() {
  return (
    <div>
      <NavbarPilot />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/About" element={<About />} />

          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />

          <Route path="/CPRClass" element={<CPRClass />} />

          <Route path="/Resources" element={<Resources />} />
          <Route path="/Healthcarestaffing" element={<Healthcarestaffing />} />
          <Route path="/RequesteSerLink" element={<RequesteSerLink />} />
          <Route path="/JoinTeam" element={<JoinTeam />} />
          {/* <Route path="/Trainingpage" element={<Trainingpage />} /> */}
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
