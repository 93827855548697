import React from "react";

function NavbarPilot() {
  return (
    <nav className="navbar" role="navigation">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#top-navbar-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a href="/">
                <img
                  style={{ marginTop: "7px" }}
                  src="/assets/img/farlogo@3x.png"
                  alt="Logo"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="collapse navbar-collapse" id="top-navbar-1">
              <ul className="nav navbar-nav navbar-right">
                <li className="">
                  <a href="/">
                    <span aria-hidden="true" className="icon_house"></span>
                    <br />
                    Home
                  </a>
                </li>
                <li>
                  <a href="/About">
                    <span
                      style={{ fontSize: "18px" }}
                      aria-hidden="true"
                      className="icon_profile"
                    ></span>
                    <br />
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/Services">
                    <span aria-hidden="true" className="">
                      <i
                        style={{ fontSize: "18px" }}
                        className="fa fa-user-md"
                      ></i>
                    </span>
                    <br />
                    Our Services
                  </a>
                  <div className="dropdown">
                    <span
                      aria-hidden="true"
                      className="arrow_triangle-down span-icon"
                    ></span>

                    <div className="dropdown-content">
                      <a href="/CPRclass">CPR</a>
                      <a href="/Healthcarestaffing">STAFFING</a>
                      <a href="/JoinTeam">JOIN OUR TEAM</a>

                      <a
                        href="https://www.fortunatetrainingcenter.com"
                        target="_blank"
                      >
                        TRAINING CENTER
                      </a>

                      <a href="/RequesteSerLink">REQUEST OUR SERVICE</a>
                    </div>
                  </div>
                </li>

                <li>
                  <a href="/Contact">
                    <span aria-hidden="true" className="icon_mail"></span>
                    <br />
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="/Resources">
                    <span aria-hidden="true" className="icon_comment"></span>
                    <br />
                    Resources
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarPilot;
